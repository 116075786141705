import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import {useStaticQuery, graphql} from 'gatsby'

const TestimonialsStyleOne = () => {
    const data = useStaticQuery(graphql`
        query FullTestimonialsQuery {
            allTestimonialsJson(sort: { fields: sort_order, order: ASC } ) {
                nodes {
                    id
                    content
                    content_short
                    person
                    profession
                    featured
                    sort_order
                }
            }
        }
    `);

    return (
        <div className="testimonials-area pt-100 pb-70 bg-f1fbf4">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="about" /> 
                        Testimonials
                    </span>
                    <h2>What Our Clients Say About Smarter Spreadsheets</h2>
                    <p>Read how Smarter Spreadsheets has helped educators save time and accomplish new tasks to meet the needs of their students.</p>
                </div>

                <div className="row">
                    {data.allTestimonialsJson.nodes.map(testimonial => {
                        return (
                            <div key={testimonial.id} className="col-lg-6 col-md-6">
                                <div className="single-testimonials-item">
                                    <p>{testimonial.content}</p>
                                    <div className="client-info">
                                        <div className="d-flex justify-content-center align-items-center">
                                            <div className="title">
                                                <h3>{testimonial.person}</h3>
                                                <span>{testimonial.profession}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    )
}

export default TestimonialsStyleOne